import React, { forwardRef, useEffect } from "react"
import styled from "styled-components"
import { HTMLPlyrVideoElement, Plyr } from "plyr-react"
import 'plyr-react/dist/plyr.css'

const videoSrc:Plyr.SourceInfo = {
  type: 'video',
  sources: [
    {
     // src: '267909138',
      src: '786047609',
      provider: 'vimeo'
    }
  ]
}

interface IHomepageVideoProps {
  className?: string
  handleClose?: () => string
  source: string
}

const HomePageVideo: React.ForwardRefExoticComponent<any> = forwardRef<HTMLVideoElement,IHomepageVideoProps>(({ className, handleClose, source }, ref) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');
      Array.from(document.querySelectorAll('#player')).map(p => new Plyr(p));
    }
  }, []);
  return (
      <div className={className}>
        <a onClick={handleClose} id="close-video" className="close-video" href="#"><i
          className="far fa-times-circle" /></a>
        {/*<div ref={ref as React.RefObject<HTMLPlyrVideoElement>} className="iframe" id="player" data-plyr-provider="vimeo" data-plyr-play="true" data-plyr-embed-id="267909138" />*/}
        {/*<Plyr ref={ref as React.RefObject<HTMLPlyrVideoElement>} options={{*/}
        {/*  autoplay: true,*/}
        {/*}} source={{*/}
        {/*  type: 'video',*/}
        {/*  sources: [*/}
        {/*    {*/}
        {/*      src: source,*/}
        {/*      provider: 'vimeo'*/}
        {/*    }*/}
        {/*  ]*/}
        {/*}} />*/}
        <div style={{padding:"56.25% 0 0 0", position:"relative"}}>
          <iframe src={`https://player.vimeo.com/video/${source}?h=0550525b0d&autoplay=1&title=0&byline=0&portrait=0`}
                  style={{position:"absolute", top:"0", left: "0", width:"100%", height:"100%"}}
                  allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
  )
})

export default styled(HomePageVideo)`
    max-width:1000px;
    margin:auto;
    position:relative;
  #close-video {
    font-size: 35px;
    color: #fff;
    position: absolute;
    background-color: #000;
    border-radius: 50%;
    left: calc(100% - 35px);
    bottom: calc(100% - 17px);
    z-index: 200;
  }
  .plyr--video {
    box-shadow: 0 0 16px 0 #c1c0c0;
  }
`

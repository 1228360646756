import React, {useState} from "react"
import styled from "styled-components"
import {useStaticQuery, graphql} from "gatsby"
import ProjectItem from "../ProjectItem"
import { FluidObject } from "gatsby-image"
import { getImage } from "gatsby-plugin-image"
import HomePageVideo from "../Video/HomePageVideo"
import { sortModulesAndAssets } from "../../util/helpers"


type ProjectListData = {
  coverImg: string
  imageCover: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  id: number
  name: string
  subInfo: string
  imagesDir: [string]
  images: {
    childImageSharp: {
      fluid: FluidObject
    }
  }[]
  pageUrl: string
}

// type ProjectData = {
//   imageFile: {
//     childImageSharp: {
//       fluid: FluidObject
//     }
//   }
// }

interface IProjectListProps {
  className?: string
  children?: React.ReactNode
  projects: {}
  lang: string
}

const PromoVideo = styled(({openVideo, source, handleClose, className}) => {
  return <div className={`${className} promo-video ${openVideo ? "open" : null}`}>
    {openVideo ?
      <HomePageVideo opened={openVideo} source={source} handleClose={handleClose} /> : null}
  </div>
})`
  padding: 100px 2% 2%;
  //@media screen and (min-width: 900px) and (max-width: 1050px) {
  //  padding-top: 150px;
  //}
  //@media screen and (min-width: 1050px) {
  //  padding-top: 7%;
  //}
  position: fixed;
  z-index: 2000;
  left:0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: none;
  &.open {
    display: block;
  }
  background: rgba(0,0,0,.9);
`

const ProjectList: React.FC<IProjectListProps> = ({ className, projects, lang }) => {

  // const projects:Array<ProjectListData> = data.allProjectsJson.nodes
  const sortedProjects = sortModulesAndAssets(projects)
  const [openVideo, setOpenVideo] = useState(false)
  const [videoID, setVideoID] = useState("")

  const handleClick = (videoId, e) => {
    setOpenVideo(true)
    setVideoID(videoId)
  }
  const closeVideo = () => {
    setOpenVideo(false)
  }
  return (
    <div className={className}>
      {projects && projects.length > 0 && projects.map((p, index) => {
        const img = getImage(p.imageFile)
        const localText = p.locales.find(l => l.iso === lang)
        const videoId = p.additionalData.find(ad => ad.key === 'VideoID').locales.find(l => l.iso === lang).value
        return <ProjectItem videoID={videoId} handleClick={handleClick} key={p.uid} index={index} project_name={localText.title} subInfo={localText.description}
                            image={img} pageUrl={localText.value} />
      })}
      <PromoVideo openVideo={openVideo} source={videoID} handleClose={closeVideo} />
    </div>
  )
}

export default styled(ProjectList)`
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`

import React from "react"
import styled from "styled-components"
import {FaPlay} from "react-icons/fa"


interface ITrrigerVideoBtnProps {
  className?: string
  handleClick?: () => void
  size?: string
  backgroundColor: string
}

const TriggerVideoBtn: React.FC<ITrrigerVideoBtnProps> = ({ className, size, backgroundColor, handleClick }) => {

  return (
    <div onClick={handleClick} className={className}>
      <a id="trigger-video" className="trigger-video" href="#">
        {/*<i className="fas fa-play-circle" />*/}
        <FaPlay size={size ? size : "40px"} />
      </a>
    </div>
  )
}

export default styled(TriggerVideoBtn)`
  position: absolute;
  z-index:995;
  left:50%;
  top:50%;
  margin-top:0;
  transform: translate(-50%, -50%);
  svg {
    position: relative;
    left:3px;
  }
  i {
    margin-right:0;
    text-shadow: 0 0 3px #b3b3b3 !important;
    font-size:45px;
    color:white;
    @media screen and (min-width:600px) {
      font-size:${({size}) => size ? size: '90px' };
    }
  }
  
  a {
    text-decoration: none;
    //background: #ff6161;
    //background-image: linear-gradient(to right, #6400a4, #5e07b5, #5411c6, #411bd8, #1225eb);
    //background-image: linear-gradient(to right,#9e3333bf,#a11111,#a20404,#d40000,#ff8308);
    background: ${({backgroundColor}) => backgroundColor ? backgroundColor : "linear-gradient(to right,#9e3333bf,#a11111,#a20404,#d40000,#ff8308)"};
    color: aliceblue;
    border-radius: 50%;
    padding: 15px;
    position: absolute;
    z-index:995;
    left:50%;
    top:50%;
    margin-top:0;
    transform: translate(-50%, -50%);
  }

  a::after {
    position: absolute;
    content: '';
    bottom: -15px;
    top: -15px;
    left: -15px;
    right: -15px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #fff;
    margin: 0px;
    opacity: 1;
    transform-origin: center;
    animation: anim 800ms linear infinite;
  }

  a::before {
    position: absolute;
    content: '';
    bottom: -15px;
    top: -15px;
    left: -15px;
    right: -15px;
    background-color: transparent;
    border-radius: 50%;
    border: 3px solid #fff;
    margin: 0px;
    opacity: 0.8;
    transform-origin: center;
  }

  @keyframes anim {
    from {
      margin: 0px;
      opacity: 1;
    }

    to {
      margin: -25px;
      opacity: 0;
    }
  }
`

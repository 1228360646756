import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
// @ts-ignore
import Fade from "react-reveal/Fade"
import TriggerVideoBtn from "../TriggerVideoBtn/TriggerVideoBtn"
import { FaPlay } from "react-icons/fa"

interface IProjectItemProps {
  className?: string
  project_name: string
  subInfo: string
  pageUrl: string
  image: FluidObject
  index?: number
  handleClick: (videoID: string, e: MouseEvent) => void
  videoID: string
}

const ProjectItem: React.FC<IProjectItemProps> = ({ className,videoID, handleClick, project_name, subInfo, pageUrl, image, index }) => {
  return (
    <Fade bottom delay={index=== 0 || index === undefined ? 0 : index*200}>
      <div className={className}>
        <div className="projects__image">
          {/*<Img fluid={image} alt="" />*/}
          <GatsbyImage alt="My image" image={image} />
        </div>
        <div className="projects__info">
          <span className="name">{project_name}</span>
          <div className="subInfo" dangerouslySetInnerHTML={{__html: `${subInfo}`}} />
          <div className="callToAction">
            <Link to={pageUrl} className="btn">View More</Link>
            { videoID ? <div onClick={handleClick.bind(null, videoID)} className="watchVideo">
              <a id="trigger-video" className="trigger-video" href="#">
                {/*<i className="fas fa-play-circle" />*/}
                {/*<FaPlay color="white" size={25} />*/}
                watch video
              </a>
            </div> : null}
          </div>
        </div>
        {videoID ? <TriggerVideoBtn backgroundColor="transparent" size="25px"  /> : null}
      </div>
    </Fade>
  )
}

export default styled(ProjectItem)`
  position: relative;
  //margin-top:25px;
  width:100%;
  overflow:hidden;
  //max-height: 206px;
  @media screen and (min-width:768px) {
    width: calc((100% - 10px)/2);
    //width: 49%;
    //margin-right:2%;
    //&:nth-child(2n + 2) {
    //  margin-right:0
    //}
  }
  .callToAction {
    display:flex;
    justify-content: center;
    align-items: center;
    gap:20px;
  }
  .watchVideo {
    a {
      text-decoration: none;
      color:white;
      text-transform: uppercase;
      padding:6.5px 15px;
      border: 1px solid white;
      font-size:13px;
    }
  }
  //.projects__image {
  //  img {
  //    max-height:238px;
  //  }
  //}
  @media screen and (min-width:1200px) {
    width:calc((100% - 20px)/3);
    //margin-right:2%;
    //&:nth-child(2n + 2) {
    //  margin-right:2%;
    //}
    //&:nth-child(3n + 3) {
    //  margin-right:0;
    //}
  }
  //&:first-child {
  //  margin-top:0;
  //  @media screen and (min-width:768px) {
  //    margin-top:25px;
  //  }
  //}
  .name {
    font-weight:bold;
    text-transform: uppercase;
    font-size:18px;
  }
  .subInfo {
    font-size:16px;
    line-height:23px;
    margin:25px 0;
  }
  &:hover {
    cursor: pointer;
    ${TriggerVideoBtn} {
      display:none;
    }
    .projects__info {
      visibility:visible;
      opacity:1;
      cursor: pointer;
      &:after {
        // width:0%;
        transform:translateX(100%)
      }
      &:before {
        transform:translateX(-100%)
      }
    }
  }
  .projects__info {
    visibility:hidden;
    opacity:0;
    position:absolute;
    padding:0 20px;
    top:0;
    left:0;
    bottom:0;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background:rgba(0,0,0,0.7);
    color:white;
    transition: all .5s ease;
    &:after {
      content: '';
      display:block;
      // visibility:hidden;
      // opacity:0;
      position: absolute;
      top:0;
      bottom:0;
      left:50%;
      width:50%;
      background:black;
      transition:all .4s ease;
    }
    &:before {
      content: '';
      display:block;
      // visibility:hidden;
      // opacity:0;
      position: absolute;
      top:0;
      bottom:0;
      right:50%;
      width:50%;
      background:black;
      transition:all .4s ease;
    }
  }
`

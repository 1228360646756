import React from "react"
import Layout from "../components/layout"
import { graphql, Link, PageProps } from "gatsby"
import SEO from "../components/seo"
import GatsbyImage, { FluidObject } from "gatsby-image"
import styled from "styled-components"
import { PageTitle, SpanDivider } from "../components/UtilComponents"
// @ts-ignore
import Fade from "react-reveal/Fade"
import ProjectSection from "../components/ProjectSection"
// @ts-ignore
import ProjectList from "../components/ProjectList"
// @ts-ignore
import { getSectionsData} from "../util/helpers"
import { BerryPageContext, BerryPageData} from "../types"

type ImageSharp = {
  childImageSharp: {
    fluid: FluidObject
  }
}

interface IAboutProps extends PageProps<BerryPageData<ImageSharp>, BerryPageContext> {
  className?: string
}

const ProjectListPage: React.FC<IAboutProps> = ({ className,location:{pathname:path}, data, pageContext }) => {
  const secData = getSectionsData(data.template.sections)
  return (
    // <p>Testing</p>
    // <Layout lang={pageContext.lang} page={path}>
    //   <SEO title="Home" />
    //   <div className={className}>
    //     <ProjectSection sectionData={data.allPortfolio} lang={pageContext.lang} />
    //   </div>
    // </Layout>

  <Layout lang={pageContext.lang} page={path}>
    <SEO title="Our Projects | Casa Italia"
    description="Quality design balances shapes, textures, colors and periods in order to create a balance of client’s personality and designer’s vision"
    keywords="Interior Architecture, Interior Design, Project Management, Space Planning, Lighting Design,
Closet Design, Kitchen and Bath Design"
    />
    <div className={className}>
      <div className="content content--projects content--inner">
        <Fade top>
          <PageTitle>
            Explore our <SpanDivider fontSize="calc(30px + (40 - 30) * ((100vw - 300px) / (900 - 300)))"> | </SpanDivider> Projects
          </PageTitle>
        </Fade>
        <section className="section projects-page">
          <p className="subheader-text">{secData.head.description[pageContext.lang]}</p>
          {/*<ProjectSection sectionData={data.allPortfolio} lang={pageContext.lang} />*/}
          <ProjectList projects={data.allPortfolio.nodes} lang={pageContext.lang} />
        </section>
      </div>
    </div>
  </Layout>
  )
}

export const query = graphql`
  {
  template(name: {eq: "project_list"}) {
    sections {
        items {
          ... on DataItemTextGet {
            __typename
            locales {
              iso
              value
            }
          }
          key
        }
        keyPrefix
      }
  }
    allPortfolio(sort: {fields: position}) {
      nodes {
        uid
        additionalData {
            key
            locales {
                iso
                value
            }
        }
        imageFile {
          childImageSharp {
              gatsbyImageData(aspectRatio: 1.5, layout: CONSTRAINED, transformOptions: {cropFocus: CENTER}, quality: 90)
          }
        }
        locales {
          iso
          title
          value
          description
        }
      }
    }
  }
`

export default styled(ProjectListPage)`
  .subheader-text {
    font-family: "California FB Regular",serif;
  }
`
